import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Card = ({ title, description, imageUrl, rating }) => {
    const whatsappNumber = '082281873662';
    const whatsappMessage = `https://wa.me/62${whatsappNumber}?text=Silahkan%20order`;

    return (
        <a
            href={whatsappMessage}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col bg-white shadow-md rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 p-4"
        >
            <div className="relative">
                <img
                    src={imageUrl}
                    alt={title}
                    className="w-full h-64 object-cover rounded-t-lg md:rounded-t-lg md:rounded-bl-lg md:rounded-br-[50px] rounded-bl-none rounded-br-none"
                />
            </div>
            <div className="flex flex-col flex-grow p-4">
                <h3 className="text-xl font-bold mb-2">{title}</h3>
                <div className="flex items-center mb-2">
                    <p className="mr-2 font-semibold">Rating Layanan</p>
                    <div className="flex">
                        {[...Array(5)].map((star, index) => (
                            <FontAwesomeIcon
                                key={index}
                                icon={faStar}
                                className={`text-yellow-500 ${index < rating ? 'fill-current' : 'opacity-25'}`}
                            />
                        ))}
                    </div>
                </div>
                <p className="flex-grow mb-4">{description}</p>
                <div className="flex items-center justify-center bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 w-full sm:w-1/2 mx-auto">
                    <FontAwesomeIcon icon={faWhatsapp} className="mr-2" />
                    WhatsApp
                </div>
            </div>
        </a>
    );
};

export default Card;
