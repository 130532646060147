import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const AboutSection = () => {
    const whatsappNumber = '082281873662';
    const whatsappMessage = `https://wa.me/62${whatsappNumber}?text=Silahkan%20order`;

    return (
        <section id="about" className="py-12 bg-white">
            <Helmet>
                <title>Tentang Mediamaz Translation Service | Penerjemah Tersumpah Indonesia</title>
                <meta name="description" content="Mediamaz Translation Service adalah perusahaan penerjemah Indonesia yang memiliki layanan 'One Stop Solution Language Company'. Kami memiliki layanan penerjemahan terlengkap di dunia, mulai dari penerjemah tersumpah, profesional, lokalisasi bahasa, AI language support, interpreter, apostille, legalisasi. Berdiri sejak tahun 1998 kami sudah berpengalaman dan beroperasi lebih dari 23 tahun." />
                <meta name="keywords" content="penerjemah tersumpah, jasa penerjemah, penerjemah dokumen legal, penerjemah tersumpah Indonesia" />
                <meta property="og:title" content="Tentang Mediamaz Translation Service | Penerjemah Tersumpah Indonesia" />
                <meta property="og:description" content="Mediamaz Translation Service adalah perusahaan penerjemah Indonesia yang memiliki layanan 'One Stop Solution Language Company'. Kami memiliki layanan penerjemahan terlengkap di dunia, mulai dari penerjemah tersumpah, profesional, lokalisasi bahasa, AI language support, interpreter, apostille, legalisasi. Berdiri sejak tahun 1998 kami sudah berpengalaman dan beroperasi lebih dari 23 tahun." />
                <meta property="og:image" content="https://via.placeholder.com/600" />
                <meta property="og:url" content="https://yourwebsite.com/about" />
            </Helmet>
            <div className="container mx-auto flex flex-col md:flex-row items-center px-4">
                <motion.div
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className="md:w-1/2 text-center md:text-left mb-8 md:mb-0"
                >
                    <h2 className="text-3xl font-bold mb-4 text-green-500">Tentang Mediamaz Translation Service</h2>
                    <p className="text-lg mb-4">
                        Mediamaz Translation Service adalah perusahaan penerjemah Indonesia yang memiliki layanan
                        "One Stop Solution Language Company". Kami memiliki layanan penerjemahan terlengkap di dunia,
                        mulai dari penerjemah tersumpah, profesional, lokalisasi bahasa, AI language support, interpreter,
                        apostille, legalisasi. Berdiri sejak tahun 1998 kami sudah berpengalaman dan beroperasi lebih dari 23 tahun.
                    </p>
                    <motion.a
                        href={whatsappMessage}
                        target="_blank"
                        rel="noopener noreferrer"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="inline-block bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition-all duration-300"
                    >
                        Download Company Profile
                    </motion.a>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className="md:w-1/2 flex justify-center"
                >
                    <iframe
                        width="100%"
                        height="250"
                        src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className="rounded-lg shadow-lg"
                    ></iframe>
                </motion.div>
            </div>
        </section>
    );
};

export default AboutSection;
