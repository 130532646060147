import React from 'react';
import { motion } from 'framer-motion';

const Hero = () => {
    const whatsappNumber = '082281873662';
    const whatsappMessage = `https://wa.me/62${whatsappNumber}?text=Silahkan%20order`;

    return (
        <section
            className="relative bg-cover bg-center h-[40rem] md:h-[50rem] pt-24" // Menambahkan padding-top dan memperbesar tinggi hero section
            style={{ backgroundImage: 'url(https://picsum.photos/1920/1080?random)' }}
        >
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: 'easeOut' }}
                    className="text-center text-white px-4" // Menambahkan padding horizontal untuk mobile view
                >
                    <motion.h1
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                        className="text-4xl md:text-5xl font-bold mb-4 text-green-500"
                    >
                        Jasa Penerjemah Tersumpah
                    </motion.h1>
                    <motion.p
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 1 }}
                        className="text-lg mb-4"
                    >
                        Hasil Terjemahan Kami Terdaftar AHU Kemenkumham Kemlu Kedutaan Asing & Lembaga Negara Indonesia
                    </motion.p>
                    <motion.p
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 1.5 }}
                        className="text-md"
                    >
                        Mediamaz telah dipercaya lebih dari 23 tahun oleh 5000++ klien korporat dan individu melayani jasa penerjemah profesional.
                    </motion.p>
                    <motion.a
                        href={whatsappMessage}
                        target="_blank"
                        rel="noopener noreferrer"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="inline-block mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition-all duration-300"
                    >
                        Hubungi Kami
                    </motion.a>
                </motion.div>
            </div>
        </section>
    );
};

export default Hero;
