import React from 'react';
import { motion } from 'framer-motion';

const Team = () => {
    const teamMembers = [
        { name: 'Dicky Priyana', title: 'Penerjemah Tersumpah', description: 'Ahli Bahasa Inggris - Indonesia dan sebaliknya, Memiliki Sertifikat SK Kemenkumham, CEO Mediamaz Translation Service.' },
        { name: 'Soesilo Hadi', title: 'Penerjemah Tersumpah', description: 'Ahli Bahasa Inggris - Indonesia dan sebaliknya, Memiliki Sertifikat SK Kemenkumham, Tim Resmi Mediamaz Translation Service.' },
        { name: 'Albert C Susanto', title: 'Penerjemah Tersumpah', description: 'Ahli Bahasa Jepang - Indonesia dan sebaliknya, Memiliki Sertifikat SK Kemenkumham, Tim Resmi Mediamaz Translation Service.' },
        { name: 'Stanchion I Pangkley', title: 'Penerjemah Tersumpah', description: 'Ahli Bahasa Taiwan - Indonesia dan sebaliknya, Memiliki Sertifikat SK Kemenkumham, Tim Resmi Mediamaz Translation Service.' },
        { name: 'Lita', title: 'Penerjemah Bersertifikat', description: 'Ahli Bahasa Korea - Indonesia dan sebaliknya, Memiliki Sertifikat Penerjemah L3 Mediamaz Work, Tim Resmi Mediamaz Translation Service.' },
        { name: 'Dedy Sugiharto', title: 'Penerjemah Tersumpah', description: 'Ahli Bahasa Jerman - Indonesia dan sebaliknya, Memiliki Sertifikat SK Kemenkumham, Tim Resmi Mediamaz Translation Service.' },
        { name: 'Kukuh', title: 'Penerjemah Tersumpah', description: 'Ahli Bahasa Spanyol - Indonesia dan sebaliknya, Memiliki Sertifikat SK Kemenkumham, Tim Resmi Mediamaz Translation Service.' },
        { name: 'Vannesa', title: 'Penerjemah Bersertifikat', description: 'Ahli Bahasa Korea - Indonesia dan sebaliknya, Memiliki Sertifikat Penerjemah L3 Mediamaz Work, Tim Resmi Mediamaz Translation Service.' },
    ];

    return (
        <section className="py-12 bg-gray-100">
            <div className="container mx-auto text-center px-4">
                <h2 className="text-3xl font-bold text-blue-700 mb-8">Tim Penerjemah Mediamaz Translation Service</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
                    {teamMembers.map((member, index) => (
                        <motion.div
                            key={index}
                            className="bg-white p-6 rounded-lg shadow-md transform transition-transform duration-300 hover:scale-105"
                        >
                            <div className="text-4xl mb-4">📘</div> {/* Placeholder untuk ikon, bisa diganti dengan ikon lain */}
                            <h3 className="text-xl font-bold mb-2">{member.name}</h3>
                            <p className="font-semibold">{member.title}</p>
                            <p>{member.description}</p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Team;
