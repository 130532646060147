import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Card from './components/Card';
import AboutSection from './components/AboutSection';
import Features from './components/Features';
import WhyMediamaz from './components/WhyMediamaz';
import Team from './components/Team';
import Gallery from './components/Gallery';
import Footer from './components/Footer';
import FAQSection from './components/FAQSection';
import FloatingWhatsAppButton from './components/FloatingWhatsAppButton'; // Import the FloatingWhatsAppButton

function App() {
  // Data dummy dengan 6 card
  const dummyData = [
    {
      title: 'Penerjemahan Dokumen',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac ligula et massa gravida faucibus.',
      imageUrl: 'https://picsum.photos/400/300?random=1'
    },
    {
      title: 'Penerjemahan Legal',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vestibulum justo at dolor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vestibulum justo at dolor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vestibulum justo at dolor.',
      imageUrl: 'https://picsum.photos/400/300?random=2'
    },
    {
      title: 'Penerjemahan Medis',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non urna ut arcu efficitur aliquet.',
      imageUrl: 'https://picsum.photos/400/300?random=3'
    },
    {
      title: 'Penerjemahan Teknis',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce scelerisque lorem eu ex.',
      imageUrl: 'https://picsum.photos/400/300?random=4'
    },
    {
      title: 'Penerjemahan Jurnal',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eget nunc in erat vestibulum.',
      imageUrl: 'https://picsum.photos/400/300?random=5'
    },
    {
      title: 'Penerjemahan Sastra',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eget nunc in erat vestibulum.',
      imageUrl: 'https://picsum.photos/400/300?random=6'
    },
  ];

  return (
    <div className="App">
      <Header />
      <Hero />
      <AboutSection />
      <main className="py-12 bg-gray-100">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
          {dummyData.map((data, index) => (
            <Card key={index} title={data.title} description={data.description} imageUrl={data.imageUrl} />
          ))}
        </div>
      </main>
      <Features />
      <WhyMediamaz />
      <Team />
      <Gallery />
      <FAQSection />
      <Footer />
      <FloatingWhatsAppButton /> {/* Add the FloatingWhatsAppButton here */}
    </div>
  );
}

export default App;
