import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faImages, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    const whatsappNumber = '082281873662';
    const whatsappMessage = `https://wa.me/62${whatsappNumber}?text=Silahkan%20order`;

    const scrollToTop = (event) => {
        event.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <header className="bg-white bg-opacity-90 text-black py-2 shadow-lg fixed w-full z-10 transition-all duration-300">
            <div className="container mx-auto flex justify-between items-center px-4">
                <div className="flex items-center">
                    <img src="https://via.placeholder.com/50" alt="Logo" className="mr-3 rounded-full" />
                    <h1 className="text-xl font-bold hover:text-gray-600 transition-colors duration-300">My Simple React App</h1>
                </div>
                <nav className="hidden md:block">
                    <ul className="flex space-x-4">
                        <li>
                            <a href="/" onClick={scrollToTop} className="hover:underline transition-transform transform hover:scale-105 flex items-center space-x-1">
                                <FontAwesomeIcon icon={faHome} />
                                <span>Home</span>
                            </a>
                        </li>
                        <li>
                            <a href="#about" className="hover:underline transition-transform transform hover:scale-105 flex items-center space-x-1">
                                <FontAwesomeIcon icon={faInfoCircle} />
                                <span>About</span>
                            </a>
                        </li>
                        <li>
                            <a href="#gallery" className="hover:underline transition-transform transform hover:scale-105 flex items-center space-x-1">
                                <FontAwesomeIcon icon={faImages} />
                                <span>Gallery</span>
                            </a>
                        </li>
                        <li>
                            <a href={whatsappMessage} className="hover:underline transition-transform transform hover:scale-105 flex items-center space-x-1">
                                <FontAwesomeIcon icon={faEnvelope} />
                                <span>Contact</span>
                            </a>
                        </li>
                    </ul>
                </nav>
                <div className="md:hidden">
                    <button className="text-gray-600 focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;
