import React from 'react';
import { motion } from 'framer-motion';

const Features = () => {
    const features = [
        {
            icon: '⏰',
            title: 'Beroperasi 23+ Tahun Lebih',
            description: 'Mediamaz Translation Service sudah berdiri sejak 1998, dalam industri penerjemahan di Indonesia kamu sudah beroperasi lebih dari 23 tahun dengan jangkauan pasar global.',
        },
        {
            icon: '🏅',
            title: 'Bersertifikat ISO 9001:2015',
            description: 'Guna meningkatkan pelayanan dan pengalaman anda. Perusahaan kami telah menetapkan SOP pelayanan dan sertifikasi langsung oleh Lembaga ISO di Indonesia.',
        },
        {
            icon: '👨‍💼',
            title: 'Tim Bersertifikat Profesional',
            description: '250+ Tim Penerjemah Tersumpah bersertifikat SK Kemenkumham, 1000+ Tim Penerjemah Profesional, 500+ Tim Interpreter yang tersebar di Indonesia.',
        },
        {
            icon: '🌍',
            title: 'Jangkauan Internasional',
            description: 'Mediamaz Translation Service memiliki jaringan terbesar di Indonesia dengan lebih dari 5 kantor cabang, kantor Singapura, Malaysia dan Qatar khusus untuk anda.',
        },
    ];

    return (
        <section className="py-12 bg-gray-300">
            <div className="container mx-auto text-center px-4">
                <h2 className="text-3xl font-bold text-green-500 mb-8">
                    Kenapa Harus Penerjemah Tersumpah Mediamaz?
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
                    {features.map((feature, index) => (
                        <motion.div
                            key={index}
                            className="bg-white p-6 rounded-lg shadow-md"
                            whileHover={{ scale: 1.05 }}
                            transition={{ duration: 0.3 }}
                        >
                            <div className="text-6xl text-green-500 mb-4">{feature.icon}</div>
                            <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                            <p>{feature.description}</p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Features;
