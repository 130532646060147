import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-white text-black py-6 shadow-inner">
            <div className="container mx-auto text-center">
                <p>© 2024 My Simple React App. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
