import React, { useState } from 'react';
import { motion } from 'framer-motion';

const FAQSection = () => {
    const whatsappNumber = '082281873662';
    const whatsappMessage = `https://wa.me/62${whatsappNumber}?text=Silahkan%20order`;

    const faqs = [
        {
            question: 'Cara Order',
            answer: 'Anda dapat memesan layanan kami dengan mengunjungi halaman Cara Order.',
        },
        {
            question: 'Jangkauan Wilayah Mediamaz TS Dukungan Kantor di Seluruh Dunia',
            answer: 'Kami memiliki kantor di berbagai negara untuk mendukung layanan internasional kami.',
        },
        {
            question: 'Bangun Karir Terbaik Bersama Mediamaz TS',
            answer: 'Mediamaz Translation Service membuka kesempatan besar bagi Anda yang tinggal di Indonesia dan memiliki keahlian atau pengalaman di bidang penerjemah teks, audio, video dan lainnya untuk bergabung dengan perusahaan penerjemah di perusahaan Kami.',
            linkText: 'Kunjungi Halaman Karir >',
        },
        {
            question: 'Kelebihan Mediamaz TS',
            answer: 'Mediamaz TS memiliki pengalaman lebih dari 23 tahun dalam bidang penerjemahan bahasa dan legalisasi dokumen.',
        },
        {
            question: 'Mengapa Harus Kami?',
            answer: 'Kami memiliki tim profesional dan bersertifikat yang siap membantu Anda dengan layanan terbaik.',
        },
    ];

    const [expanded, setExpanded] = useState(null);

    const toggleExpand = (index) => {
        setExpanded(expanded === index ? null : index);
    };

    return (
        <section className="py-12 bg-white text-black">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold mb-4 text-green-500">Dapatkan Penawaran Dokumen Anda Sekarang</h2>
                <a
                    href={whatsappMessage}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-8 inline-block"
                >
                    Upload Dokumen Disini
                </a>
                <div className="flex flex-col md:flex-row justify-between items-start">
                    <div className="md:w-1/3 mb-8 md:mb-0">
                        <h3 className="text-xl font-bold mb-4 text-green-500">F.A.Q</h3>
                        <a href="/faq" className="text-green-500 underline">Pelajari Lebih Lanjut</a>
                    </div>
                    <div className="md:w-2/3">
                        {faqs.map((faq, index) => (
                            <div key={index} className="mb-4">
                                <h4
                                    className="text-lg font-bold cursor-pointer text-green-500"
                                    onClick={() => toggleExpand(index)}
                                >
                                    {faq.question}
                                </h4>
                                {expanded === index && (
                                    <motion.div
                                        initial={{ height: 0, opacity: 0 }}
                                        animate={{ height: 'auto', opacity: 1 }}
                                        transition={{ duration: 0.3 }}
                                        className="overflow-hidden"
                                    >
                                        <p className="text-black mt-2">{faq.answer}</p>
                                        {faq.linkText && <a href="/karir" className="text-green-500 underline">{faq.linkText}</a>}
                                    </motion.div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FAQSection;
