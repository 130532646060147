import React from 'react';

const Gallery = () => {
    const images = [
        'https://picsum.photos/600/400?random=6',
        'https://picsum.photos/600/400?random=7',
        'https://picsum.photos/600/400?random=8',
        'https://picsum.photos/600/400?random=9',
        'https://picsum.photos/600/400?random=10',
        'https://picsum.photos/600/400?random=11',
    ];

    return (
        <section id="gallery" className="py-12 bg-gray-100">
            <div className="container mx-auto text-center px-4">
                <h2 className="text-3xl font-bold mb-8">Gallery</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {images.map((src, index) => (
                        <div key={index} className="overflow-hidden rounded-lg shadow-md">
                            <img
                                src={src}
                                alt={`Gallery ${index}`}
                                className="w-full h-64 object-cover transition-transform duration-300 transform hover:scale-105"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Gallery;
