import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const FloatingWhatsAppButton = () => {
    const whatsappNumber = '082281873662';
    const whatsappMessage = `https://wa.me/62${whatsappNumber}?text=Silahkan%20order`;

    return (
        <a
            href={whatsappMessage}
            target="_blank"
            rel="noopener noreferrer"
            className="fixed bottom-4 right-4 bg-green-500 hover:bg-green-700 text-white flex items-center p-4 rounded-full shadow-lg transition-all duration-300 z-50"
        >
            <FontAwesomeIcon icon={faWhatsapp} size="lg" />
            <span className="ml-2">WhatsApp 24 Jam</span>
        </a>
    );
};

export default FloatingWhatsAppButton;
