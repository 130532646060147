import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faGraduationCap, faCheck, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

const WhyMediamaz = () => {
    const reasons = [
        {
            icon: faBriefcase,
            title: 'Berpengalaman',
            description: 'Berdiri sejak tahun 1998, Mediamaz TS memiliki pengalaman yang besar dalam bidang penerjemahan bahasa dan legalisasi dokumen ke kementerian untuk pasar Indonesia/global.',
        },
        {
            icon: faGraduationCap,
            title: 'Bersertifikat',
            description: 'Kami memiliki tim Penerjemah tersumpah yang memiliki SK Gubernur dan Kemenkumham serta tim penerjemah yang sudah bersertifikat HPI dan internasional.',
        },
        {
            icon: faCheck,
            title: 'ISO 9001:2015',
            description: 'Mediamaz TS memiliki sertifikat ISO 9001:2015 yang berfungsi dan terimplementasi kedalam sistem pengecekan kualitas dari hasil terjemahan Kami agar memuaskan.',
        },
        {
            icon: faClipboard,
            title: 'Terdaftar',
            description: 'Dengan kualitas, pengalaman dan sumber daya besar yang Kami miliki, hasil terjemahan Mediamaz kini telah mendapatkan pengakuan oleh kementerian & kedutaan asing di Indonesia.',
        },
    ];

    return (
        <section className="py-12" style={{ backgroundImage: 'url(https://picsum.photos/1920/1080?random)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="container mx-auto flex flex-col md:flex-row items-center bg-white bg-opacity-90 p-8 rounded-lg shadow-lg">
                <motion.div
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className="md:w-1/2"
                >
                    <img
                        src="https://picsum.photos/600/400?random"
                        alt="Mediamaz Team"
                        className="w-full rounded-lg shadow-md"
                    />
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className="md:w-1/2 mt-8 md:mt-0 md:pl-12"
                >
                    <h2 className="text-3xl font-bold text-green-500 mb-8">Kenapa Harus Mediamaz Translation Service?</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {reasons.map((reason, index) => (
                            <motion.div
                                key={index}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="flex items-start space-x-4 p-4 border rounded-lg shadow-lg transition-all duration-300 bg-white bg-opacity-90"
                            >
                                <div className="text-green-500 text-4xl">
                                    <FontAwesomeIcon icon={reason.icon} />
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold mb-2">{reason.title}</h3>
                                    <p>{reason.description}</p>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </motion.div>
            </div>
        </section>
    );
};

export default WhyMediamaz;
